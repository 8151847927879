<script>
import StaticPageLayout from '@/templates/static/StaticPageLayout'

export default {
  metaInfo() {
    return {
      title: 'Search syntax - Help - Europe PMC',
    }
  },
  components: { StaticPageLayout },
  data() {
    return {
      title: 'Search syntax reference',
      sections: [
        {
          id: 'direct-search',
          heading: 'h2',
          title: '1. Direct search',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'field-search',
          heading: 'h2',
          title: '2. Field search',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
          children: [
            {
              id: 'corebibliographic',
              title: '2.1 Core bibliographic',
              highlight: false,
            },
            {
              id: 'articlemetadata',
              title: '2.2 Article metadata',
              highlight: false,
            },
            {
              id: 'fulltextavailability',
              title: '2.3 Full text availability',
              highlight: false,
            },
            {
              id: 'collectionmetadata',
              title: '2.4 Collection metadata',
              highlight: false,
            },
            {
              id: 'books',
              title: '2.5 Books',
              highlight: false,
            },
            {
              id: 'databasecrossreferences',
              title: '2.6 Database cross references',
              highlight: false,
            },
            {
              id: 'databasecitations',
              title: '2.7 Database Citations',
              highlight: false,
            },
            {
              id: 'sectionlevelsearch',
              title: '2.8 Section-level search',
              highlight: false,
            },
          ],
        },
        {
          id: 'searchcombinations',
          heading: 'h2',
          title: '3. Search combinations',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
    }
  },
}
</script>
<template>
  <div id="search-syntax-page">
    <static-page-layout :title="title" :sections="sections">
      <p slot="section-start-slot">
        This site offers an
        <a href="/advancesearch">advanced search</a> function as well the quick
        search function direct from the search box. Below is a table that
        describes basic syntax form and examples of how these can be used.
      </p>
      <template slot-scope="{ section }">
        <template v-if="section.id === 'direct-search'">
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Search syntax</th>
                  <th>Result</th>
                </tr>
                <tr>
                  <td>J.Inorg. Biochem. 101:74-79(2007)</td>
                  <td>
                    Quick search in common notation style, with journal + volume
                    + pageInfo + pub year
                  </td>
                </tr>
                <tr>
                  <td>microtubule glycosylated</td>
                  <td>
                    Finds citations containing microtubule AND glycosylated
                    (default behaviour)
                  </td>
                </tr>
                <tr>
                  <td>microtubule OR glycosylated</td>
                  <td>
                    Finds citations containing microtubule OR glycosylated. Note
                    that "OR" is case sensitive.
                  </td>
                </tr>
                <tr>
                  <td>(fish OR bird) AND disease</td>
                  <td>
                    Finds citations containing either fish OR bird AND on top of
                    that disease.
                  </td>
                </tr>
                <tr>
                  <td>microtub* disease</td>
                  <td>
                    Finds citations containing disease AND terms that start with
                    microtub. Wildcard usage is limited and not allowed when
                    resulting in too many clauses.
                  </td>
                </tr>
                <tr>
                  <td>microtubule cardiac -toxicity</td>
                  <td>
                    Finds citations containing microtubule AND cardiac but NOT
                    toxicity
                  </td>
                </tr>
                <tr>
                  <td>With quotes: "green grass"</td>
                  <td>
                    "green grass" (with quotes) results in a search for
                    <em>green grass</em> as a sequence of two words. Bear in
                    mind that when you use stop words (such as
                    'a','and','in','if', etc.) within a quoted phrase search
                    this may give less accurate results due to the nature of the
                    indexing mechanism.
                  </td>
                </tr>
                <tr>
                  <td>
                    Without quotes or single quotes: green grass or 'green
                    grass'
                  </td>
                  <td>
                    Using no quotes or single quotes is always less strict, and
                    will give back all citations where the words green and grass
                    occur, not necessarily sequentially.
                  </td>
                </tr>
                <tr>
                  <td>Hyphenated words: "non-vaccinated"</td>
                  <td>
                    If you are searching for a hyphenated word, e.g.,
                    "non-vaccinated", you must enclose it in quotes to ensure it
                    is treated as a single phrase. Without quotes, the search
                    may return results where the words appear separately, such
                    as "non-hospitalized vaccinated patients" or "vaccinated
                    non-infected individuals."
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else-if="section.id === 'field-search'">
          <p>
            Note: <a href="/advancesearch">Advanced search</a> uses field
            searches under the hood.
          </p>

          <h3 id="corebibliographic">2.1 Core bibliographic</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>EXT_ID:</td>
                  <td>
                    Search for a publication by external ID: i.e. the ID
                    assigned to a publication at repository level. Together with
                    the publication's source, they form a unique id of the
                    publication. Click <a href="/Help#searchbyID">here</a> for
                    more details.
                  </td>
                  <td>
                    <a href="/search?query=EXT_ID:10826746">EXT_ID:10826746</a>
                  </td>
                </tr>
                <tr>
                  <td>PMCID:</td>
                  <td>
                    Search for a publication by its PubMed Central ID, where
                    applicable (i.e. available as full text)
                  </td>
                  <td>
                    <a href="/search?query=PMCID:PMC1287968"
                      >PMCID:PMC1287968</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>TITLE:</td>
                  <td>Search for a term or terms in publication titles</td>
                  <td>
                    <a href="/search?query=title:aspirin">TITLE:aspirin</a>,
                    <a href="/search?query=title:%22protein+knowledgebase%22"
                      >TITLE:"protein knowledgebase"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>ABSTRACT:</td>
                  <td>Search for a term or terms in publication abstracts</td>
                  <td>
                    <a href="/search?query=ABSTRACT:malaria">ABSTRACT:malaria</a
                    >,
                    <a href="/search?query=ABSTRACT:%22chicken%20pox%22"
                      >ABSTRACT:"chicken pox"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>TITLE_ABS:</td>
                  <td>
                    Search for a term or terms in publication titles and
                    abstracts
                  </td>
                  <td>
                    <a href="/search?query=TITLE_ABS:blood">TITLE_ABS:blood</a>,
                    <a
                      href="/search?query=TITLE_ABS:%22african%20swine%20fever%22"
                      >TITLE_ABS:"african swine fever"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>PUB_YEAR:</td>
                  <td>
                    Search by year of publication in YYYY format; note syntax
                    for range searching.
                  </td>
                  <td>
                    <a href="/search?query=PUB_YEAR:2000">PUB_YEAR:2000</a>,
                    <a href="/search?query=PUB_YEAR:%5B2000+TO+2001%5D"
                      >PUB_YEAR:[2000 TO 2001]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>E_PDATE:</td>
                  <td>
                    Electronic publication date, when an article was first
                    published online.
                  </td>
                  <td>
                    <a href="/search?query=E_PDATE%3A2013-12-15"
                      >E_PDATE:2013-12-15</a
                    >,
                    <a href="/search?query=E_PDATE%3A20070930"
                      >E_PDATE:20070930</a
                    >,
                    <a
                      href="/search?query=E_PDATE%3A%5B2000-12-18%20TO%202014-12-30%5D"
                      >E_PDATE:[2000-12-18 TO 2014-12-30]</a
                    >,
                    <a
                      href="/search?query=E_PDATE%3A%5B20040101%20TO%2020140101%5D"
                      >E_PDATE:[20040101 TO 20140101]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>FIRST_PDATE:</td>
                  <td>
                    The date of first publication, whichever is first,
                    electronic or print publication. Where a date is not fully
                    available e.g. year only, an algorithm is applied to
                    determine the value.
                  </td>
                  <td>
                    <a href="/search?query=FIRST_PDATE%3A1995-02-01"
                      >FIRST_PDATE:1995-02-01</a
                    >,
                    <a href="/search?query=FIRST_PDATE%3A20000101"
                      >FIRST_PDATE:20000101</a
                    >,
                    <a
                      href="search?query=FIRST_PDATE%3A%5B2000-10-14%20TO%202010-11-15%5D"
                      >FIRST_PDATE:[2000-10-14 TO 2010-11-15]</a
                    >,
                    <a
                      href="/search?query=FIRST_PDATE%3A%5B20040101%20TO%2020140101%5D"
                      >FIRST_PDATE:[20040101 TO 20140101]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>P_PDATE:</td>
                  <td>
                    Print publication date of journal issue, when an article
                    appeared in print format.
                  </td>
                  <td>
                    <a href="/search?query=P_PDATE%3A1982-10-01"
                      >P_PDATE:1982-10-01</a
                    >,
                    <a href="/search?query=P_PDATE%3A20140101"
                      >P_PDATE:20140101</a
                    >,
                    <a
                      href="/search?query=P_PDATE%3A%5B2000-12-18%20TO%202014-12-30%5D"
                      >P_PDATE:[2000-12-18 TO 2014-12-30]</a
                    >,
                    <a
                      href="/search?query=P_PDATE%3A%5B20031114%20TO%2020141115%5D"
                      >P_PDATE:[20031114 TO 20141115]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>FIRST_IDATE:</td>
                  <td>
                    The date the publication was first indexed in Europe PMC.
                  </td>
                  <td>
                    <a href="/search?query=FIRST_IDATE%3A2019-02-01"
                      >FIRST_IDATE:2019-02-01</a
                    >,
                    <a href="/search?query=FIRST_IDATE%3A20190101"
                      >FIRST_IDATE:20190101</a
                    >,
                    <a
                      href="/search?query=FIRST_IDATE%3A%5B2018-10-14%20TO%202018-11-15%5D"
                      >FIRST_IDATE:[2018-10-14 TO 2018-11-15]</a
                    >,
                    <a
                      href="/search?query=FIRST_IDATE%3A%5B20190101%20TO%2020190101%5D"
                      >FIRST_IDATE:[20190101 TO 20190101]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>EMBARGO_DATE:</td>
                  <td>
                    The date from which Europe PMC is permitted to provide
                    access to the full text article. This date is held for
                    articles received from March 2015.
                  </td>
                  <td>
                    <a
                      href="/search?query=EMBARGO_DATE%3A%5B2015-03-01%20TO%202015-03-31%5D"
                      >EMBARGO_DATE:[2015-03-01 TO 2015-03-31]</a
                    >,
                    <a href="/search?query=EMBARGO_DATE%3A2015-03-01"
                      >EMBARGO_DATE:2015-03-01</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>EMBARGOED_MAN:</td>
                  <td>
                    Limit search results according to accessibility of the full
                    text article regarding the EMBARGO_DATE. Assuming you
                    request EMBARGOED_MAN:Y at the date 20160101 than this will
                    be translated into EMBARGO_DATE:[20160101 TO *] and
                    EMBARGOED_MAN:N to EMBARGO_DATE:[* TO 20160101].
                  </td>
                  <td>
                    <a href="/search?query=EMBARGOED_MAN%3AY">EMBARGOED_MAN:Y</a
                    >,
                    <a href="/search?query=EMBARGOED_MAN%3AN"
                      >EMBARGOED_MAN:N</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>AUTH:</td>
                  <td>
                    Search for a surname and (optionally) initial(s) in
                    publication author lists
                  </td>
                  <td>
                    <a href="/search?query=AUTH:einstein">AUTH:einstein</a>,
                    <a href="/search?query=AUTH:%22einstein+G%22"
                      >AUTH:"einstein G"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>INVESTIGATOR:</td>
                  <td>
                    Search for a publication by specifying an investigator
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=INVESTIGATOR:%22Orlandini+F%22"
                      >INVESTIGATOR:"Orlandini F"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>AUTHORID_TYPE:</td>
                  <td>List publications that are associated with an ORCID</td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=AUTHORID_TYPE:ORCID&amp;sortby=Relevance"
                      >AUTHORID_TYPE:ORCID</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>AUTHORID:</td>
                  <td>
                    List publications that are associated with a specified ORCID
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=AUTHORID:%220000-0002-1767-9318%22&amp;sortby=Relevance"
                      >AUTHORID:"0000-0002-1767-9318"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>AFF:</td>
                  <td>
                    Search for a term or terms in the author affiliation field
                  </td>
                  <td>
                    <a href="/search?query=AFF:ebi">AFF:ebi</a>,
                    <a href="/search?query=AFF:%22university+of+cambridge%22"
                      >AFF:"university of cambridge"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>JOURNAL:</td>
                  <td>
                    Journal title – searchable either in full or abbreviated
                    form
                  </td>
                  <td>
                    <a href="/search?query=JOURNAL:%22biology+letters%22"
                      >JOURNAL:"biology letters"</a
                    >,
                    <a href="/search?query=JOURNAL:%22biol+lett%22"
                      >JOURNAL:"biol lett"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>ISSN:</td>
                  <td>
                    Search for a journal by its ISSN; see the NCBI’s list of
                    journals and ISSNs in PubMed
                  </td>
                  <td>
                    <a href="/search?query=ISSN:0028-0836">ISSN:0028-0836</a>
                  </td>
                </tr>
                <tr>
                  <td>VOLUME:</td>
                  <td>
                    Search for journal volumes, most useful in combination with
                    fields: JOURNAL/PUB_YEAR/VOLUME/ISSUE
                  </td>
                  <td>
                    <a
                      href="/search?query=JOURNAL:%22biology+letters%22+VOLUME:10"
                      >JOURNAL:"biology letters" VOLUME:10</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>ISSUE:</td>
                  <td>
                    Search for journal issues, most useful in combination with
                    fields: JOURNAL/PUB_YEAR/VOLUME/ISSUE
                  </td>
                  <td>
                    <a
                      href="/search?query=journal:%22biology+letters%22+volume:10+issue:2"
                      >JOURNAL:"biology letters" VOLUME:10 ISSUE:2</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>LICENSE:</td>
                  <td>
                    Search for content according to the assigned Creative
                    Commons license (where provided). The values assigned by
                    publishers have been grouped as follows:
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY</span>: "cc", "cc by",
                      "cc-by"
                    </div>
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY-SA</span>: "cc", "cc by sa",
                      "cc-by sa", "cc by-sa", "cc-by-sa"
                    </div>
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY-ND</span>: "cc", "cc by nd",
                      "cc-by nd", "cc by-nd", "cc-by-nd"
                    </div>
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY-NC</span>: "cc", "cc by nc",
                      "cc-by nc", "cc by-nc", "cc-by-nc"
                    </div>
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY-NC-ND</span>: "cc", "cc by
                      nc nd", "cc-by nc nd", "cc by-nc nd", "cc by nc-nd",
                      "cc-by-nc nd", "cc by-nc-nd", "cc-by nc-nd", "cc-by-nc-nd"
                    </div>
                    <div class="small-padding-top">
                      <span class="semi-bold">CC BY-NC-SA</span>: "cc", "cc by
                      nc sa", "cc-by nc sa", "cc by-nc sa", "cc by nc-sa",
                      "cc-by-nc sa", "cc by-nc-sa", "cc-by nc-sa", "cc-by-nc-sa"
                    </div>
                  </td>
                  <td>
                    <a
                      href="/search?query=LICENSE:%22cc+by+sa%22+OR+LICENSE:%22cc-by+sa%22+OR+LICENSE:%22cc+by-sa%22+OR+LICENSE:%22cc-by-sa%22"
                      >LICENSE:"cc by sa" OR LICENSE:"cc-by sa" OR LICENSE:"cc
                      by-sa" OR LICENSE:"cc-by-sa"</a
                    >
                    <br /><br />
                    returns the same count as: <br /><br /><a
                      href="/search?query=LICENSE%3A%22CC%20BY-SA%22"
                      >LICENSE:"CC BY-SA"</a
                    >
                    <br /><br />
                    All articles that have a Creative Commons license assigned
                    can be found as follows:
                    <a href="/search?query=LICENSE%3Acc">LICENSE:cc</a>
                  </td>
                </tr>
                <tr>
                  <td>EPMC_AUTH_MAN:</td>
                  <td>
                    Identify manuscripts that have been submitted via the Europe
                    PMC plus (formerly UKPMC+) Manuscript Submission System
                  </td>
                  <td>
                    <a href="/search?scope=abstract&amp;query=EPMC_AUTH_MAN%3Ay"
                      >EPMC_AUTH_MAN:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>NIH_AUTH_MAN:</td>
                  <td>
                    Identify manuscripts that have been submitted the NIH
                    Manuscript Submission System
                  </td>
                  <td>
                    <a href="/search?query=NIH_AUTH_MAN%3Ay">NIH_AUTH_MAN:y</a>
                  </td>
                </tr>
                <tr>
                  <td>AUTH_MAN:</td>
                  <td>
                    Search for an article that has been submitted via a
                    Manuscript Submission System
                  </td>
                  <td><a href="/search?query=AUTH_MAN%3Ay">AUTH_MAN:y</a></td>
                </tr>
                <tr>
                  <td>AUTH_MAN_ID:</td>
                  <td>
                    Find an article by specifying an manuscript submission ID
                  </td>
                  <td>
                    <a href="/search?query=AUTH_MAN_ID%3AEMS59581"
                      >AUTH_MAN_ID:EMS59581</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="articlemetadata">2.2 Article metadata</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>DISEASE:</td>
                  <td>Search for mined diseases</td>
                  <td>
                    <a href="/search?query=DISEASE:dysthymias"
                      >DISEASE:dysthymias</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>GENE_PROTEIN:</td>
                  <td>Search for records that have GENE_PROTEINS mined</td>
                  <td>
                    <a href="/search?query=GENE_PROTEIN:gng11"
                      >GENE_PROTEIN:gng11</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>GOTERM:</td>
                  <td>Search for records that have GOTERM mined</td>
                  <td>
                    <a href="/search?query=GOTERM:apoptosis"
                      >GOTERM:apoptosis</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>LANG:</td>
                  <td>
                    Limit your search to publications written in language X; see
                    the Advanced Search page for a full list of languages
                  </td>
                  <td>
                    <a href="/search?query=LANG:fre">LANG:fre</a>,
                    <a href="/search?query=LANG:eng">LANG:eng</a>
                  </td>
                </tr>
                <tr>
                  <td>GRANT_AGENCY:</td>
                  <td>
                    Limit your search by the funding agency which supported the
                    research; see the Advanced Search page for a full list of
                    grant agencies
                  </td>
                  <td>
                    <a href="/search?query=GRANT_AGENCY:%22Wellcome%20Trust%22"
                      >GRANT_AGENCY:"wellcome trust"</a
                    >,
                    <a
                      href="/search?query=GRANT_AGENCY:%22medical+research+council%22"
                      >GRANT_AGENCY:"medical research council"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>GRANT_ID:</td>
                  <td>
                    Limit your search by ID of the grant which funded the
                    research
                  </td>
                  <td>
                    <a href="/search?query=GRANT_ID:100229">GRANT_ID:100229</a>,
                    <a href="/search?query=GRANT_ID:71672">GRANT_ID:71672</a>
                  </td>
                </tr>
                <tr>
                  <td>KW:</td>
                  <td>
                    Limit your search by keyword, including MeSH and other
                    publisher-supplied terms
                  </td>
                  <td>
                    <a href="/search?query=KW:galactosylceramides"
                      >KW:galactosylceramides</a
                    >,
                    <a href="/search?query=KW:%22recombinant+proteins%22"
                      >KW:"recombinant proteins"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CHEM:</td>
                  <td>Limit your search by MeSH substance</td>
                  <td>
                    <a href="/search?query=CHEM:propantheline"
                      >CHEM:propantheline</a
                    >,
                    <a href="/search?query=CHEM:%22protein+kinases%22"
                      >CHEM:"protein kinases"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_ABSTRACT:</td>
                  <td>
                    Limit search results according to presence or absence of
                    abstract
                  </td>
                  <td>
                    <a href="/search?query=HAS_ABSTRACT:y">HAS_ABSTRACT:y</a>,
                    <a href="/search?query=HAS_ABSTRACT:n">HAS_ABSTRACT:n</a>
                  </td>
                </tr>
                <tr>
                  <td>ORGANISM:</td>
                  <td>Search for mined organisms</td>
                  <td>
                    <a href="/search?query=ORGANISM:terebratulide"
                      >ORGANISM:terebratulide</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>PUB_TYPE:</td>
                  <td>
                    Limit your search by publication type; see the Advanced
                    Search page for a full list of publication types
                  </td>
                  <td>
                    <a href="/search?query=PUB_TYPE:review">PUB_TYPE:review</a>,
                    <a
                      href="/search?query=PUB_TYPE:%22retraction+of+publication%22"
                      >PUB_TYPE:"retraction of publication"</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="fulltextavailability">2.3 Full text availability</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>BODY:</td>
                  <td>
                    Search for terms within the body of a fulltext article
                  </td>
                  <td><a href="/search?query=BODY:PCR">BODY:PCR</a></td>
                </tr>
                <tr>
                  <td>DOI:</td>
                  <td>
                    Search for publication by Digital Object Identifier (DOI)
                  </td>
                  <td>
                    <a href="/search?query=DOI:10.1007/bf00197367"
                      >DOI:10.1007/bf00197367</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_DOI:</td>
                  <td>
                    Limit search results to those publications which do or do
                    not have a DOI
                  </td>
                  <td>
                    <a href="/search?query=HAS_DOI:y">HAS_DOI:y</a>,
                    <a href="/search?query=HAS_DOI:n">HAS_DOI:n</a>
                  </td>
                </tr>
                <tr>
                  <td>IN_PMC:</td>
                  <td>
                    Limit search results according to availability (or not) of
                    fulltext article in PubMed Central
                  </td>
                  <td><a href="/search?query=IN_PMC:y">IN_PMC:y</a></td>
                </tr>
                <tr>
                  <td>HAS_FT:</td>
                  <td>
                    Limit search results according to availability (or not) of
                    fulltext article or book in Europe PMC
                  </td>
                  <td><a href="/search?query=HAS_FT:y">HAS_FT:y</a></td>
                </tr>
                <tr>
                  <td>HAS_FREE_FULLTEXT:</td>
                  <td>
                    Limit search results according to availability (or not) of a
                    link to a known, free-to-read version of the full text
                    article
                  </td>
                  <td>
                    <a href="/search?query=HAS_FREE_FULLTEXT:y"
                      >HAS_FREE_FULLTEXT:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>IN_EPMC:</td>
                  <td>
                    Limit search results according to availability (or not) of
                    fulltext article in Europe PMC
                  </td>
                  <td><a href="/search?query=IN_EPMC:y">IN_EPMC:y</a></td>
                </tr>
                <tr>
                  <td>HAS_PDF:</td>
                  <td>
                    Limit search results according to availability (or not) of a
                    PDF version of the fulltext article
                  </td>
                  <td><a href="/search?query=HAS_PDF:y">HAS_PDF:y</a></td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="collectionmetadata">2.4 Collection metadata</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>SRC:</td>
                  <td>
                    Search for articles from a particular repository; the
                    available data sources are listed are listed in the 'code'
                    column of the table under the question:
                    <a href="/Help#whatserachingEPMC"
                      >What am I searching on Europe PMC?</a
                    >
                  </td>
                  <td>
                    <a href="/search?query=SRC:ctx">SRC:ctx</a>,
                    <a href="/search?query=SRC:hir">SRC:hir</a>,
                    <a href="/search?query=SRC%3Appr">SRC:ppr</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_XREFS:</td>
                  <td>
                    Limit search results to articles with cross-references to
                    other databases; the available databases are listed in the
                    Web Services Reference Guide
                  </td>
                  <td><a href="/search?query=HAS_XREFS:y">HAS_XREFS:y</a></td>
                </tr>
                <tr>
                  <td>HAS_PREPRINT:</td>
                  <td>
                    Limit search results to peer reviewed articles which were
                    preceded by a preprint version of the article
                  </td>
                  <td>
                    <a href="/search?query=HAS_PREPRINT:y">HAS_PREPRINT:y</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_PUBLISHED_VERSION:</td>
                  <td>
                    Limit search results to preprints which were later peer
                    reviewed, edited, typeset and published as articles
                  </td>
                  <td>
                    <a href="/search?query=HAS_PUBLISHED_VERSION:y"
                      >HAS_PUBLISHED_VERSION:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_VERSION_EVALUATIONS:</td>
                  <td>
                    Limit search results to articles where one or more versions
                    of the article has a review or evaluation
                  </td>
                  <td>
                    <a href="/search?query=HAS_VERSION_EVALUATIONS:y"
                      >HAS_VERSION_EVALUATIONS:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_TM:</td>
                  <td>
                    Limit search results to text-mined fulltext articles only
                    (or not)
                  </td>
                  <td><a href="/search?query=HAS_TM:y">HAS_TM:y</a></td>
                </tr>
                <tr>
                  <td>HAS_REFLIST:</td>
                  <td>
                    Limit search results to only those publications with a
                    reference list (or not)
                  </td>
                  <td>
                    <a href="/search?query=HAS_REFLIST:y">HAS_REFLIST:y</a>
                  </td>
                </tr>
                <tr>
                  <td>CREATION_DATE:</td>
                  <td>
                    Search for publications by date of entry into the Europe PMC
                    database, in YYYY-MM-DD format; note syntax for searching
                    date range
                  </td>
                  <td>
                    <a href="/search?query=CREATION_DATE:2010-11-11"
                      >CREATION_DATE:2010-11-11</a
                    >,
                    <a
                      href="/search?query=CREATION_DATE:%5B2010-11-11+TO+2010-12-11%5D"
                      >CREATION_DATE:[2010-11-11 TO 2010-12-11]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>UPDATE_DATE:</td>
                  <td>
                    Search for publications by date of update in the Europe PMC
                    database; note syntax for searching date range
                  </td>
                  <td>
                    <a href="/search?query=UPDATE_DATE:2011-11-11"
                      >UPDATE_DATE:2011-11-11</a
                    >,
                    <a
                      href="/search?query=UPDATE_DATE:%5B2011-11-11+TO+2011-12-11%5D"
                      >UPDATE_DATE:[2011-11-11 TO 2011-12-11]</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>OPEN_ACCESS:</td>
                  <td>Limit search results to articles that are Open Access</td>
                  <td>
                    <a href="/search?query=OPEN_ACCESS:y">OPEN_ACCESS:y</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_LABSLINKS:</td>
                  <td>
                    Lists articles that have links provided by 3rd parties
                    (using the
                    <a href="/LabsLink">Europe PMC external links service</a>)
                  </td>
                  <td>
                    <a href="/search?query=HAS_LABSLINKS:y">HAS_LABSLINKS:y</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_SUPPL:</td>
                  <td>
                    List articles that have supplemental data associated with
                    them e.g. spreadsheets, video files, etc.
                  </td>
                  <td><a href="/search?query=HAS_SUPPL:y">HAS_SUPPL:y</a></td>
                </tr>
                <tr>
                  <td>LABS_PUBS:</td>
                  <td>List articles that have external links by provider ID</td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=LABS_PUBS:1001&amp;sortby=Relevance"
                      >LABS_PUBS:1001</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="books">2.5 Books</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>ISBN:</td>
                  <td>Search for book by ISBN where indexed</td>
                  <td>
                    <a href="/search?query=ISBN:9780815340720"
                      >ISBN:9780815340720</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>ED:</td>
                  <td>Search for book by editor</td>
                  <td>
                    <a href="/search?query=ED%3Ajensen%20HAS_BOOK%3Ay"
                      >ED:jensen HAS_BOOK:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>PUBLISHER:</td>
                  <td>Search for book by publisher</td>
                  <td>
                    <a
                      href="/search?query=PUBLISHER:%22OUP%20Oxford%22%20HAS_BOOK:y"
                      >PUBLISHER:"OUP Oxford" HAS_BOOK:y</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_BOOK:</td>
                  <td>List the full text books on the Europe PMC Bookshelf</td>
                  <td><a href="/search?query=HAS_BOOK:y">HAS_BOOK:y</a></td>
                </tr>
                <tr>
                  <td>BOOK_ID:</td>
                  <td>
                    Find a full text book on the Europe PMC Bookshelf by
                    specifying its 'NBK' number
                  </td>
                  <td>
                    <a href="/search?query=BOOK_ID:NBK27326"
                      >BOOK_ID:NBK27326</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="databasecrossreferences">2.6 Database cross references</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>ARXPR_PUBS:</td>
                  <td>Show publications with links to given ArrayExpress ID</td>
                  <td>
                    <a href="/search?page=1&amp;query=ARXPR_PUBS:E-GEOD-22481"
                      >ARXPR_PUBS:E-GEOD-22481</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>UNIPROT_PUBS:</td>
                  <td>Show publications with links to given UniProt ID</td>
                  <td>
                    <a href="/search?query=UNIPROT_PUBS:q1rfz4"
                      >UNIPROT_PUBS:q1rfz4</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>EMBL_PUBS:</td>
                  <td>Show publications with links to given EMBL ID</td>
                  <td>
                    <a href="/search?query=EMBL_PUBS:KJ634683"
                      >EMBL_PUBS:KJ634683</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>PDB_PUBS:</td>
                  <td>Show publications with links to given PDBe ID</td>
                  <td>
                    <a href="/search?query=PDB_PUBS:2w3z">PDB_PUBS:2w3z</a>
                  </td>
                </tr>
                <tr>
                  <td>INTACT_PUBS:</td>
                  <td>Show publications with links to given IntAct ID</td>
                  <td>
                    <a href="/search?query=INTACT_PUBS:*">INTACT_PUBS:*</a>
                  </td>
                </tr>
                <tr>
                  <td>INTERPRO_PUBS:</td>
                  <td>Show publications with links to given InterPro ID</td>
                  <td>
                    <a href="/search?query=INTERPRO_PUBS:ipr013998"
                      >INTERPRO_PUBS:ipr013998</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CHEBITERM:</td>
                  <td>Search for mined chemical names</td>
                  <td>
                    <a href="/search?query=CHEBITERM:dihydrocapsaicin"
                      >CHEBITERM:dihydrocapsaicin</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CHEBI_PUBS:</td>
                  <td>Show publications with links to given ChEBI ID</td>
                  <td>
                    <a href="/search?query=CHEBI_PUBS:chebi:62806"
                      >CHEBI_PUBS:chebi:62806</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>HAS_ARXPR:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the ArrayExpress catalog and link to the relevant
                    ArrayExpress record
                  </td>
                  <td><a href="/search?query=HAS_ARXPR:y">HAS_ARXPR:y</a></td>
                </tr>
                <tr>
                  <td>HAS_UNIPROT:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the UniProt catalog and link to the relevant
                    UniProt record
                  </td>
                  <td>
                    <a href="/search?query=HAS_UNIPROT:y">HAS_UNIPROT:y</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_EMBL:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the EMBL database and link to the relevant EMBL
                    record
                  </td>
                  <td><a href="/search?query=HAS_EMBL:y">HAS_EMBL:y</a></td>
                </tr>
                <tr>
                  <td>HAS_PDB:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the PDBe database and link to the relevant PDBE
                    record
                  </td>
                  <td><a href="/search?query=HAS_PDB:y">HAS_PDB:y</a></td>
                </tr>
                <tr>
                  <td>HAS_INTACT:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the IntAct database and link to the relevant IntAct
                    record
                  </td>
                  <td><a href="/search?query=HAS_INTACT:y">HAS_INTACT:y</a></td>
                </tr>
                <tr>
                  <td>HAS_INTERPRO:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the InterPro database and link to the relevant
                    InterPro record
                  </td>
                  <td>
                    <a href="/search?query=HAS_INTERPRO:y">HAS_INTERPRO:y</a>
                  </td>
                </tr>
                <tr>
                  <td>HAS_CHEBI:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by the ChEBI dictionary and link to the relevant ChEBI
                    record
                  </td>
                  <td><a href="/search?query=HAS_CHEBI:y">HAS_CHEBI:y</a></td>
                </tr>
                <tr>
                  <td>HAS_CHEMBL:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by ChEMBL and link to the relevant ChEMBL record
                  </td>
                  <td><a href="/search?query=HAS_CHEMBL:y">HAS_CHEMBL:y</a></td>
                </tr>
                <tr>
                  <td>HAS_OMIM:</td>
                  <td>
                    Limit search results to publications which are (or are not)
                    cited by OMIM and link to the relevant OMIM record
                  </td>
                  <td><a href="/search?query=HAS_OMIM:y">HAS_OMIM:y</a></td>
                </tr>
                <tr>
                  <td>CITES:</td>
                  <td>
                    Search for publications that cite a given article; article
                    to be specified in the format, ID_src
                  </td>
                  <td>
                    <a href="/search?query=CITES:8521067_med"
                      >CITES:8521067_med</a
                    >,
                    <a href="/search?query=CITES:IND43783977_agr"
                      >CITES:IND43783977_agr</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CITED:</td>
                  <td>Search for publications that have been cited N times</td>
                  <td><a href="/search?query=CITED:100">CITED:100</a></td>
                </tr>
                <tr>
                  <td>REFFED_BY:</td>
                  <td>
                    Search for publications that cite the specified article;
                    format, ID_source (the available data sources are listed in
                    the Web Services Reference Guide)
                  </td>
                  <td>
                    <a href="/search?query=REFFED_BY:9497246_med"
                      >REFFED_BY:9497246_med</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="databasecitations">2.7 Database Citations</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example&gt;</th>
                </tr>
                <tr>
                  <td>ACCESSION_ID:</td>
                  <td>
                    Find articles containing the specified accession number
                  </td>
                  <td>
                    <a href="/search?query=ACCESSION_ID:A12360"
                      >ACCESSION_ID:A12360</a
                    >
                  </td>
                </tr>
                <tr>
                  <td rowspan="20">ACCESSION_TYPE:</td>
                  <td class="multiple_rows">
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/arrayexpress/">ArrayExpress</a>
                    records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:arrayexpress"
                      >ACCESSION_TYPE:arrayexpress</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/ena">BioProject</a>
                    records in the European Nucleotide Archive
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:bioproject"
                      >ACCESSION_TYPE:bioproject</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/biosamples/">BioSamples</a>
                    records
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:biosample"
                      >ACCESSION_TYPE:biosample</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite data
                    <a href="//www.doi.org/">Document Object Identifiers</a>
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:doi&amp;sortby=relevance"
                      >ACCESSION_TYPE:doi</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite European Genome-phenome Archive (<a
                      href="//www.ebi.ac.uk/ega/"
                      >EGA</a
                    >) records
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:ega"
                      >ACCESSION_TYPE:ega</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite EM resources in the Electron
                    Microscopy Data Bank (<a href="//www.ebi.ac.uk/pdbe/emdb/"
                      >EMDB</a
                    >)
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:emdb"
                      >ACCESSION_TYPE:emdb</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ensembl.org/index.html">Ensembl</a>
                    records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:ensembl&amp;sortby=relevance"
                      >ACCESSION_TYPE:ensembl</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite EudraCT records from the
                    <a href="//www.clinicaltrialsregister.eu/ctr-search/search"
                      >EU Clinical Trials Register</a
                    >
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:eudract&amp;sortby=relevance"
                      >ACCESSION_TYPE:eudract</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/ena/"
                      >European Nucleotide Archive</a
                    >
                    records
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:gen"
                      >ACCESSION_TYPE:gen</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/QuickGO/">Gene Ontology</a>
                    records
                  </td>
                  <td>
                    <a href="/search?page=1&amp;query=ACCESSION_TYPE:go"
                      >ACCESSION_TYPE:go</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ebi.ac.uk/interpro/">InterPro</a>
                    records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:interpro&amp;sortby=relevance"
                      >ACCESSION_TYPE:interpro</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite NCT clinical studies records from
                    the US NIH
                    <a href="//clinicaltrials.gov/ct2/home"
                      >ClinicalTrials.gov</a
                    >
                    registry
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:nct&amp;sortby=relevance"
                      >ACCESSION_TYPE:nct</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.ncbi.nlm.nih.gov/omim">OMIM</a>
                    records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:omim&amp;sortby=relevance"
                      >ACCESSION_TYPE:omim</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite Protein Data Bank in Europe (<a
                      href="//www.ebi.ac.uk/pdbe/"
                      >PDB</a
                    >) records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:pdb&amp;sortby=relevance"
                      >ACCESSION_TYPE:pdb</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite protein families (<a
                      href="//pfam.xfam.org/"
                      >Pfam</a
                    >) records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:pfam&amp;sortby=relevance"
                      >ACCESSION_TYPE:pfam</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.proteomexchange.org/">ProteomeXchange</a>
                    records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:pxd&amp;sortby=relevance"
                      >ACCESSION_TYPE:pxd</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite Reference Sequence Database (<a
                      href="//www.ncbi.nlm.nih.gov/refseq/"
                      >RefSeq</a
                    >) records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:refseq&amp;sortby=relevance"
                      >ACCESSION_TYPE:refseq</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite records in the dbSNP Short Genetic
                    Variations database (<a
                      href="//www.ncbi.nlm.nih.gov/projects/SNP/"
                      >RefSNP</a
                    >)
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:refsnp&amp;sortby=relevance"
                      >ACCESSION_TYPE:refsnp</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite the resource of protein sequence and
                    functional information (<a href="//www.ebi.ac.uk/uniprot/"
                      >UniProt</a
                    >)
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:uniprot&amp;sortby=relevance"
                      >ACCESSION_TYPE:uniprot</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    Find articles that cite
                    <a href="//www.treefam.org/">Treefam</a>
                    (database of animal gene trees) records
                  </td>
                  <td>
                    <a
                      href="/search?page=1&amp;query=ACCESSION_TYPE:treefam&amp;sortby=relevance"
                      >ACCESSION_TYPE:treefam</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 id="sectionlevelsearch">2.8 Section-level search</h3>
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Syntax word</th>
                  <th>Description</th>
                  <th>Example</th>
                </tr>
                <tr>
                  <td>ABBR:</td>
                  <td>
                    Find articles with word "mRNA" in the Abbreviations section
                  </td>
                  <td>
                    <a href="/search?query=ABBR:mRNA&amp;sortby=relevance"
                      >ABBR:mRNA</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>ACK_FUND:</td>
                  <td>
                    Find articles with word "ERC" in the Acknowledgements &amp;
                    Funding section
                  </td>
                  <td>
                    <a href="/search?query=ACK_FUND:ERC&amp;sortby=relevance"
                      >ACK_FUND:ERC</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>APPENDIX:</td>
                  <td>
                    Find articles with word "ethics" in the Appendix section
                  </td>
                  <td>
                    <a href="/search?query=APPENDIX:ethics&amp;sortby=relevance"
                      >APPENDIX:ethics</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>AUTH_CON:</td>
                  <td>
                    Find articles with phrase "Smith" in the Author Contribution
                    section
                  </td>
                  <td>
                    <a
                      href="/search?query=AUTH_CON:%22Smith%22&amp;sortby=relevance"
                      >AUTH_CON:"Smith"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CASE:</td>
                  <td>
                    Find articles with word "leukemia" in the Case Study section
                  </td>
                  <td>
                    <a href="/search?query=CASE:leukemia&amp;sortby=relevance"
                      >CASE:leukemia</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>COMP_INT:</td>
                  <td>
                    Find articles with phrase "no conflict" in the Competing
                    Interest section
                  </td>
                  <td>
                    <a
                      href="/search?query=COMP_INT:%22no+conflict%22&amp;sortby=relevance"
                      >COMP_INT:"no conflict"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>CONCL:</td>
                  <td>
                    Find articles with word "osteoporosis" in the Conclusion
                    section
                  </td>
                  <td>
                    <a
                      href="/search?query=CONCL:osteoporosis&amp;sortby=relevance"
                      >CONCL:osteoporosis</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>DISCUSS:</td>
                  <td>
                    Find articles with word "cardiovascular" in the Discussion
                    section
                  </td>
                  <td>
                    <a
                      href="/search?query=DISCUSS:cardiovascular&amp;sortby=relevance"
                      >DISCUSS:cardiovascular</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>FIG:</td>
                  <td>
                    Find articles with phrase "in vitro" in the Figures section
                  </td>
                  <td>
                    <a
                      href="/search?query=FIG:%22in+vitro%22&amp;sortby=relevance"
                      >FIG:"in vitro"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>INTRO:</td>
                  <td>
                    Find articles with phrase "protein interactions" in the
                    Introduction &amp; Background section
                  </td>
                  <td>
                    <a
                      href="/search?query=INTRO:%22protein+interactions%22&amp;sortby=relevance"
                      >INTRO:"protein interactions"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>KEYWORD:</td>
                  <td>
                    Find articles with word "isoform" in the Keywords section
                  </td>
                  <td>
                    <a href="/search?query=KEYWORD:isoform&amp;sortby=relevance"
                      >KEYWORD:isoform</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>METHODS:</td>
                  <td>
                    Find articles with phrase "yeast two-hybrid" in the
                    Materials &amp; Methods section
                  </td>
                  <td>
                    <a
                      href="/search?query=METHODS:%22yeast+two-hybrid%22&amp;sortby=relevance"
                      >METHODS:"yeast two-hybrid"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>OTHER:</td>
                  <td>
                    Find articles with phrase "transgenic mice" in the Others
                    section
                  </td>
                  <td>
                    <a
                      href="/search?query=OTHER:%22transgenic+mice%22&amp;sortby=relevance"
                      >OTHER:"transgenic mice"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>REF:</td>
                  <td>
                    Find articles with word "COX2" in the References section
                  </td>
                  <td>
                    <a href="/search?query=REF:COX2&amp;sortby=relevance"
                      >REF:COX2</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>RESULTS:</td>
                  <td>
                    Find articles with phrase "in vivo" in the Results section
                  </td>
                  <td>
                    <a
                      href="/search?query=RESULTS:%22in+vivo%22&amp;sortby=relevance"
                      >RESULTS:"in vivo"</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>SUPPL:</td>
                  <td>
                    Find articles with word "supplementary" in the Supplementary
                    Information section, indicating where supplementary data
                    might be available
                  </td>
                  <td>
                    <a
                      href="/search?query=SUPPL:supplementary&amp;sortby=relevance"
                      >SUPPL:supplementary</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>TABLE:</td>
                  <td>
                    Find articles with word "comparison" in the Tables section
                  </td>
                  <td>
                    <a
                      href="/search?query=TABLE:comparison&amp;sortby=relevance"
                      >TABLE:comparison</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
        <template v-else-if="section.id === 'searchcombinations'">
          <div class="table-overflow">
            <table class="dark">
              <tbody>
                <tr>
                  <th>Combination</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>
                    <a href="/search?query=TITLE%3Apancreatitis%09IN_EPMC%3Ay"
                      >TITLE:pancreatitis IN_EPMC:y</a
                    >
                  </td>
                  <td>
                    Finds citations in Europe PMC with word pancreatitis in
                    title
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?query=AUTH%3Afrossard%20TITLE%3Aacute%20pancreatitis"
                      >AUTH:frossard TITLE:acute pancreatitis</a
                    >
                  </td>
                  <td>
                    Finds citations from author Frossard with acute pancreatitis
                    in the title
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/search?query=TITLE%3Apanc*%20AUTH%3Afrossard"
                      >TITLE:panc* AUTH:frossard</a
                    >
                  </td>
                  <td>
                    Finds citations from author Frossard where a word in the
                    title starts with 'panc' i.e pancreatic, pancreas, PANCEA
                    <i
                      >(A question mark represents a wildcard for one position,
                      the star any number of positions)</i
                    >
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/search?query=JOURNAL%3Alancet%09AUTH%3Afrossard"
                      >JOURNAL:lancet AUTH:frossard</a
                    >
                  </td>
                  <td>
                    Finds citations in journal The Lancet from author Frossard
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?query=ISSN%3A0140-6736%09AUTH%3Afrossard%20%20%22acute%20pancreatitis%22"
                      >ISSN:0140-6736 AUTH:frossard "acute pancreatitis"</a
                    >
                  </td>
                  <td>
                    Finds citations from journal The Lancet (ISSN 0140-6736)
                    with author Frossard and phrase "acute pancreatitis"
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?query=AUTH%3Afrossard%20AUTH%3Asteer%20HAS_ABSTRACT%3Ay"
                      >AUTH:frossard AUTH:steer HAS_ABSTRACT:y</a
                    >
                  </td>
                  <td>Finds abstracts by authors Steer and Frossard</td>
                </tr>
                <tr>
                  <td>
                    <a href="/search?query=GRANT_ID%3A058922%20IN_EPMC%3Ay"
                      >GRANT_ID:058922 IN_EPMC:y</a
                    >
                  </td>
                  <td>
                    Finds citations in Europe PMC with grant 058922 attached
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="/search?query=GRANT_ID%3A%22058992%22%20AUTH%3Anoor"
                      >GRANT_ID:"058992" AUTH:noor</a
                    >
                  </td>
                  <td>
                    Finds citations containing author Noor with grant 058992
                    attached
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/search?query=GRANT_ID%3A05*%20AUTH%3Aamin"
                      >GRANT_ID:05* AUTH:amin</a
                    >
                  </td>
                  <td>
                    Finds citations containing author Amin and a grant starting
                    05
                    <i
                      >(A question mark represents a wildcard for one position,
                      the star any number of positions)</i
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </template>
    </static-page-layout>
  </div>
</template>
<style lang="scss" scoped>
.table-overflow {
  overflow: auto;
  table {
    width: 100%;
  }
}
.small-padding-top {
  padding-top: $base-unit * 1.25;
}
</style>
